<template>
    <div class="m-2">
       <div class="content-header my-3">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark capitalize">
                       Report Daftar Penjualan Barang
                    </h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <!-- <ol class="breadcrumb float-sm-right ">
                        <li>
                            <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                        </li>
                        <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"  @click="showModal"><span class="fas fa-plus"> </span>  Add Merchant</button></li>
                    </ol> -->
                </div><!-- /.col -->
            </div><!-- /.row -->
             <div class="alert alert-danger alert-dismissible" v-if="error">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                <h5>
                    <i class="icon fa fa-ban"></i>
                    failed!
                </h5>
                <p>{{ error }}</p>
            </div>
            <div class="row mb-2">
                <div class="col-sm-2">
                     <div class="form-group">
                        <label for="recipient-status" class="col-form-label">Status</label>
                        <select class="form-control" v-model="status">
                            <option value="">Semua Status</option>
                            <option value="Paid">Paid</option>
                            <option value="Pending">Pending</option>
                            <option value="Expired">Expired</option>
                        </select>
                    </div>
                </div><!-- /.col -->
                <div class="col-sm-2">
                     <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Date Mulai</label>
                        <input type="date" class="form-control" id="recipient-name" v-model="dateStart">
                    </div>
                </div><!-- /.col -->
                 <div class="col-sm-2">
                   <div class="form-group">
                        <label for="recipient-name" class="col-form-label">Date Selesai</label>
                        <input type="date" class="form-control" id="recipient-name" v-model="dateEnd">
                    </div>
                </div><!-- /.col -->
                <div class="col-sm align-self-end">
                 <div class="form-group">
                         <button type="button" class="btn btn-primary btn-lg"  @click="btnDownload">Download</button>
                    </div>
                   
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>      
        <data-tables-master-data :config="config" id="sampleTable"></data-tables-master-data>
                
    </div>
</template>


<script>
const {console} = window
import DataTablesMasterData from '@components/DataTablesMasterData.vue'
export default {
     components: {
        DataTablesMasterData
    },

    data() {
        return {
            dateStart:"",
            dateEnd:"",
            status:"",
            error: undefined,
            config: {
                columns: [{
                    title: 'Tanggal',
                    data: 'created',
                    render: (val) => {
                        return this.formatDate(val)
                    } 
                },{
                    title: 'SKU',
                    data: 'product.sku'
                }, {
                    title: 'Nama Barang',
                    data: 'product.name'
                }, {
                    title: 'ID Transaksi',
                    data: 'transaction.transaction_code'
                },{
                    title: 'Qty',
                    data: 'amount'
                    
                },{
                    title: 'Harga',
                    data: 'product_fee',
                     render: (val) => {
                        return this.formatPrice(val)
                    }   
                },
                {
                    title: 'Total Harga',
                    data: 'total_product_fee',
                     render: (val) => {
                        return this.formatPrice(val)
                    }   
                },{
                    title: 'Merchant',
                    data: 'transaction.merchant.name'
                    
                },{
                    title: 'Vending ID',
                    data: 'transaction.vending.vending_id'
                    
                },{
                    title: 'status',
                    data: 'transaction.status'
                    
                }],
                url:this.Api.base + '/report',
                order: [[0, "desc"]],
            }
        }
    },
    mounted() {
    },
    props: {
      value: Object,
    },
    methods: {
        formatDate(value) {
            return moment(String(value)).format('DD-MMM-YYYY HH:mm')
        },
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        btnDownload(){
             if(this.dateStart != "" & this.dateEnd !=""){
                let linkurl = '/report/download/saleofgood/'+this.dateStart+'/'+this.dateEnd+''
                let saveAs = 'laporan_penjualan_produk.xls'
                let filters='?filters=[["transaction.status","like","'+this.status+'"]]'
                linkurl = linkurl+filters
                Api.download(linkurl, saveAs).then(() => {
                    console.log('download complete')
                    this.error=null;
                }).catch((err) => {
                    this.error = "Data tidak tersedia dalam laporan"
                })
             }else{
                this.error = "Data Tanggal Mulai dan Tanggal Selesai Harus Diisi"
             }
        },

        
    },
}
</script>
